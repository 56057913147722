<template>
  <div class="progressBar" ref="progressBar">
    <div ref="progressBarDiv"></div>
  </div>
</template>

<script>
/*
  * Progress bar that counts down to 0 in steps of 1 second.
  * */
import $ from 'jquery'
export default {
  name: 'Loader',
  props: {
    /*
      * The amount of time left on the progress bar.
      * */
    timeLeft: {
      type: Number,
      default: 3
    },
    /*
      * The total time to count down from. Used to calculate steps.
      * */
    timeTotal: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      /*
        * Mutable property that contains the prop value of timeLeft
        * */
      tLeft: 0,
      /*
        * Mutable property that contains the prop value of timeTotal
        * */
      tTotal: 0,
      /*
        * Property to keep track if the bar needs to be restarted to stop recursion of previous loop.
        * */
      restart: false,
      /*
        * Property to keep track if the bar is already running to know if we need to stop recursion from a previous
        * loop.
        * */
      running: false
    }
  },
  methods: {
    /*
      * This method is called recursively to progress the bar. Also considers if the bar is already running and resets
      * it if needed.
      * */
    _progress () {
      this.running = true
      const barWidth = this.tLeft * this.$refs.progressBar.clientWidth / this.tTotal
      $(this.$refs.progressBarDiv).animate({ width: barWidth }, 500)
      if (this.tLeft > 0 && !this.restart) {
        setTimeout(() => {
          this.tLeft -= 1
          this._progress()
        }, 1000)
      } else {
        // If this part is triggered by a restart it means a new loop was started so it is till running.
        // We only want to set running to false if the tLeft is 0
        if (!this.tLeft) {
          this.running = false
        }
        this.restart = false
      }
    },
    /*
      * Public method to start the bar.
      * */
    progress () {
      // If the bar is not running we don't want to set the restart flag because otherwise the bar will be stuck.
      if (this.running) {
        this.restart = true
      }
      // Need these to combat Vue shenanigans
      this.tLeft = JSON.parse(JSON.stringify(this.timeLeft))
      this.tTotal = JSON.parse(JSON.stringify(this.timeTotal))
      this._progress(this.tLeft, this.tTotal)
    }
  }
}
</script>

<style scoped>
  .progressBar {
    width: 100%;
    margin: 10px auto;
    height: 5px;
    background-color: #f5f5f5;
  }

  .progressBar div {
    height: 100%;
    text-align: right;
    line-height: 5px;
    width: 0;
    background-color: #1ab394;
    box-sizing: border-box;
  }
</style>
